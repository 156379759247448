import http from '@/utils/http';

// 获取
export const getVenueList = (params) => {
    return http.request({
        url: "/v1/olympics/venue/list",
        method: 'get',
        params: params,
    })
}

// 更新
export const updateVenue = (params) => {
    return http.request({
        url: "/v1/olympics/venue/update",
        method: 'post',
        data: params,
    })
}

// 搜索
export const searchVenue = (params) => {
    return http.request({
        url: "/v1/olympics/venue/search",
        method: 'post',
        data: params,
    })
}
