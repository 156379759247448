import http from '@/utils/http';

// 获取
export const getDelegationPlayerList = (params) => {
    return http.request({
        url: "/v1/olympics/delegation/player/list",
        method: 'get',
        params: params,
    })
}

// 更新
export const updateDelegationPlayer = (params) => {
    return http.request({
        url: "/v1/olympics/delegation/player/update",
        method: 'post',
        data: params,
    })
}
