<template>
    <div>
        <Modal
            v-model="modal_add_relation"
            :title="modal_add_title"
            @on-ok="modal_add_on_ok"
        >
            <Form label-position="left" :label-width="100">
                <!-- <FormItem label="运动:">
                    <Select v-model="new_relation.sport_id" >
                        <Option v-for="(item, index) in $conf.sports || []" :value="item.id" :key="index">{{ item.name }}</Option>
                    </Select>
                </FormItem> -->
                <FormItem label="日期&运动:">
                    <Row>
                        <Col span=12>
                            <DatePicker type='date' format="yyyy-MM-dd" v-model="date" placeholder="选择日期" ></DatePicker>
                        </Col>
                        <Col span=12>
                            <Select v-model="new_relation.sport_id"   placeholder="选择运动项目" >
                                <Option v-for="(item, index) in $conf.sports || []" :value="item.id" :key="index">{{ item.name }}</Option>
                            </Select>
                        </Col>
                    </Row>
                </FormItem>
                <FormItem label="赛程搜索:">
                    <namiMatchSearch :sport_id="new_relation.sport_id" :date="date" v-model="new_relation.match"  ></namiMatchSearch>
                </FormItem>
            </Form>
            
        </Modal>
        <Card :bordered="false" :padding="0" :dis-hover="true">
            <p slot="title">
                纳米赛程关联
            </p>
            <a href="#" slot="extra" @click.prevent="addMatchRelation" v-if="data.id === 0">
                <Icon type="md-add"></Icon>
                添加关联
            </a>
            <Row class-name="relation-row" v-else="data.id > 0">
                <Col class-name="relation-head" span="4">{{data.id}}</Col>
                <Col class-name="relation-content" span="8"><span class="text-main"> {{ getMatchTime(data.match_time)}} </span></Col>
                <Col class-name="relation-content" span="10"><span class="text-main"> {{data.home.name}} vs {{data.away.name}}</span></Col>
                <Col class-name="relation-close" span="2"> <Icon @click.native="rmRelation( )" type="ios-close-circle-outline" /></Col>
            </Row>
        </Card>
    </div>
</template>
<script>

import { getMatchRelation,  setMatchRelation, deleteMatchRelation } from '@/api/olympics/match_relation';
import moment from 'moment';
import namiMatchSearch from '../../ballsports/match/search.vue';

export default {
    name: "schedule-relation",
    props: {
        _id: Number,   // 比赛id
    },
    components: {
        // imgEditor
        namiMatchSearch,
    },
    data () {
        let self = (window.x = this)
        return {
            loading: true,
            data: {
                "id": 0,
                "home": {},
                "away": {},
            },
            date: '',

            modal_add_relation: false,
            modal_add_title: '', // 模态框标题
            new_relation: {
                sport_id: 0,     
                match: {
                    home:{},
                    away:{},
                },      
            },
        }
    },
    methods: {
        getMatchTime(tsp){
            return tsp>0? moment.unix(tsp).format('YYYY-MM-DD HH:mm'): ''
        },
        // end 
        getData () {
            let self = this;
            this.loading = true
            // 搜索日期
            if (self._id){
                let params = {
                    "id": this._id,
                }
                getMatchRelation( params).then(response => {
                    // console.log(response)
                    if (response.data.code === 0) {
                        self.data = response.data.data || 
                            {
                                "id": 0,
                                "home": {},
                                "away": {},
                            };
                        self.loading = false;
                    } else {
                        self.$Message.error(response.data.msg);
                    }
                })
            }else{
                self.data = {
                    "id": 0,
                    "home": {},
                    "away": {},
                }
            }

        },
        rmRelation(){
            let self = this;
            // console.log("delete: ", _type, item, index)
            let params = {
                sport_id: self.data.sport_id,
                match_id: self.data.id,
                olympic_match_id: self._id,
            }
            deleteMatchRelation( params).then(response => {
                // console.log(response)
                if (response.data.code === 0) {
                    self.getData();
                } else {
                    self.$Message.error(response.data.msg);
                }
            })
        },
        _initMatchRelation(){
            this.new_relation = {
                sport_id: 0,     
                match: {
                    home:{},
                    away:{},
                },
            }
        },
        addMatchRelation(){
            let self = this;
            self._initMatchRelation();
            self.modal_add_title = "赛程关联"
            self.modal_add_relation = true;
        },
        
        // end 
        modal_add_on_ok () {
            let self = this;
            // console.log(self.new_relation)
            let params = {
                sport_id: self.new_relation.sport_id,
                match_id: self.new_relation.match.id,
                olympic_match_id: self._id,
            }
            setMatchRelation( params).then(response => {
                // console.log(response)
                if (response.data.code === 0) {
                    self.getData();
                    self.modal_add_relation = false;
                } else {
                    self.$Message.error(response.data.msg);
                }
            })
        },
        // end 
    },
    mounted () {
    },
    watch: {
        _id(value){
            let self = this;
            self.getData();
        },
        // end 
    },
    computed: {
    }
}
</script>
<style scoped>
    @import "relation.css"
</style>