<template>
    <div>
        <Modal
            v-model="modal_add_player"
            title="新增选手"
            @on-ok='addNewPlayer'
        >
            <playerSearch  v-model="new_player" > </playerSearch>
        </Modal>

        <Spin size="large" fix v-if="spinShow"></Spin>
        <Tabs value="1">
            <TabPane label="基本信息" name="1">
                <Form label-position="left" :label-width="80" >
                    <FormItem label="id: " v-if="data.id">
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.id" disabled></Input>
                            </Col>
                        </Row>
                    </FormItem>

                    <FormItem label="选手: ">
                        <Row v-for="(item, index) in data.players" :key="index" >
                            <Col span="22" >
                                <!-- {{item}} -->
                                <Row>
                                    <Col span="12" >
                                        <span class="text-main"> {{item.name}} </span>
                                    </Col>
                                    <Col span="8" >
                                        <span class="text-link"> {{item.id}} </span>
                                    </Col>
                                    <Col span="4" >
                                        <Icon type="md-close-circle"  @click.native="data.players.splice(index, 1)" />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col span="20" >
                                <Button shape="circle" icon="md-add" @click="modal_add_player=true" ></Button>
                            </Col>
                        </Row>
                    </FormItem>

                    <FormItem label="地区: ">
                        <Row>
                            <Col span="20" >
                                <areaSearch v-model="data.area" ></areaSearch>
                            </Col>
                        </Row>
                    </FormItem>

                    <FormItem label="代表团: ">
                        <Row>
                            <Col span="20" >
                                <delegateSearch v-model="data.delegate" :season_id="season_id"></delegateSearch>
                            </Col>
                        </Row>
                    </FormItem>

                    <FormItem label="泳道: ">
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.extra.lang"></Input>
                            </Col>
                        </Row>
                    </FormItem>

                    <!-- <FormItem label="是否删除: ">
                        <Row>
                            <Col span="20" >
                                <Select v-model="data.deleted" >
                                    <Option :value="1" key="1">是</Option>
                                    <Option :value="0" key="0">否</Option>
                                </Select>
                            </Col>
                        </Row>
                    </FormItem> -->
                </Form>
            </TabPane>
            <!-- <TabPane label="额外信息" name="2">
                <Form label-position="left" :label-width="80" >
                    <FormItem label="泳道: ">
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.extra.lang"></Input>
                            </Col>
                        </Row>
                    </FormItem>
                </Form>
            </TabPane> -->
        </Tabs>
        <Row align="middle">
            <Button type="primary"  @click="save" long size="default">保存</Button>
        </Row>
    </div>
</template>
<script>

import { getPlayerList } from '@/api/olympics/player';
import { getDelegationPlayerList } from '@/api/olympics/delegation_player';
import { getScheduleLineupList, updateScheduleLineup, } from '@/api/olympics/schedule';
import playerSearch from '../../player/search.vue';
import areaSearch from '../../area/search.vue';
import delegateSearch from '../../delegation/search.vue';

export default {
    name: "schedule-lineup-edit",
    props: {
        _id: Number,   // id
        season_id: Number,
        schedule_id: Number,
    },
    components: {
        playerSearch,
        areaSearch,
        delegateSearch,
    },
    data () {
        let self = (window.x = this)
        return {
            spinShow: false,
            modal_add_player: false,
            new_player: {
                id: 0,
                name: '',
            },
            data: {
                id: 0,
                area: {},
                delegate: {},
                players: [],
                extra: {},
                deleted: 0,
            },
        }
    },
    methods: {
        clearData(){
            this.data = {
                id: 0,
                area: {},
                delegate: {},
                players: [],
                extra: {},
                deleted: 0,
            }
        },
        // 获取联赛分类
        getData () {
            let self = this;
            if (self._id && self._id > 0){
                this.spinShow = true;
                // 搜索日期
                var params = {
                    "id" : self._id,
                }
                getScheduleLineupList( params ).then(response => {
                    if (response.data.code == 0) {
                        if (response.data.data.list && response.data.data.list.length > 0){
                            this.data = response.data.data.list[0];
                        }else{
                            this.data = {};
                        }
                        self.spinShow = false;
                    } else {
                        self.$Message.error(response.data.msg);
                    }
                })
            }else{
                self.clearData();
            }
        },
        // end
        emit(func_name){
            this.$emit('closePage', func_name);
        },

        save() {
            let self = this;
            var player_ids = [];
            self.data.players.forEach(element => {
                if (element.id && element.id>0 && player_ids.indexOf(element.id) == -1){
                    player_ids.push(element.id);
                };
            });
            // 额外信息字段
            // 1泳道
            if (self.data.extra.lang){
                if( parseInt(self.data.extra.lang) && parseInt(self.data.extra.lang) > 0 ){
                    self.data.extra.lang = parseInt(self.data.extra.lang)
                }else{
                    delete self.data.extra['lang'];
                }
            }
            let params = {
                id: self.data.id,
                season_id: self.season_id,
                schedule_id: self.schedule_id,
                area_id: self.data.area.id,
                delegate_id: self.data.delegate.id,
                player_ids: player_ids,
                extra: self.data.extra,
                deleted: self.data.deleted,
            }
            // console.log(params);
            updateScheduleLineup(params).then(response => {
                if (response.data.code == 0) {
                    this.$Message.success("保存成功");
                    self.emit("save");
                } else {
                    this.$Message.error(response.data.msg)
                }
            })
        },
        // end 
        addNewPlayer(){
            let self = this;
            if (self.new_player.id > 0){
                self.data.players.push( self.new_player );
                
                var params = {
                    "id" : self.new_player.id,
                }
                getPlayerList( params ).then(response => {
                    if (response.data.code == 0) {
                        if (response.data.data.list && response.data.data.list.length > 0){
                            var player = response.data.data.list[0];
                            if (player){
                                // 国家地区
                                if (player.area.id && player.area.id > 0){
                                    self.data.area = player.area;
                                }
                                // 如果属于团体的话 直接将团体下的所有的选手直接写入到数据
                                // console.log(player);
                                if (player.subs && player.subs.length > 0){
                                    // let subs = player.subs;
                                    player.subs.forEach(element => {
                                        self.data.players.push( element );
                                    })
                                }
                            }
                        }
                    } else {
                    }
                });
                // end
                getDelegationPlayerList({
                    season_id: self.season_id,
                    athlete_id: self.new_player.id,
                })
                .then(response => {
                    if (response.data.code == 0) {
                        if (response.data.data.list && response.data.data.list.length > 0){
                            var delegate_data = response.data.data.list[0];
                            if (delegate_data){
                                if (delegate_data.delegate && delegate_data.delegate.id > 0){
                                    self.data.delegate = delegate_data.delegate ;
                                }
                            }
                        }
                    } else {
                        self.$Message.error(response.data.msg);
                    }
                });
                // end
                
            }
            self.new_player = {
                id: 0,
                name: '',
            }
        },
        // end
    },
    mounted () {
        // let self = this;
    },
    watch: {
        "_id":{
            handler(value){
                // console.log( value, this.season_id, this.schedule_id );
                let self = this;
                self.getData();
            },
	        immediate: true,  // 深度触发
        },
        "season_id": {
            handler(value){
                // console.log( "season_id", this.season_id, this.schedule_id );
                let self = this;
                self.getData();
            },
	        immediate: true,  // 深度触发
        },
        'schedule_id': {
            handler(value){
                // console.log( "schedule_id", this.season_id, this.schedule_id );
                let self = this;
                self.getData();
            },
	        immediate: true,  // 深度触发
        },
    },
    computed: {
    }
}
</script>
