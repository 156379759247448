import http from '@/utils/http';

// 获取 列表

// 根据奥运会id获取单条
export const getMatchRelation = (params) => {
    return http.request({
        url: "/v1/olympics/match/relation/get",
        method: 'get',
        params: params,
    })
}
// 新增
export const setMatchRelation = (params) => {
    return http.request({
        url: "/v1/olympics/match/relation/set",
        method: 'post',
        data: params,
    })
}

// 删除
export const deleteMatchRelation = (params) => {
    return http.request({
        url: "/v1/olympics/match/relation/delete",
        method: 'post',
        data: params,
    })
}
