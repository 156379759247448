import http from '@/utils/http';

// 获取国家/地区
export const getScheduleList = (params) => {
    return http.request({
        url: "/v1/olympics/schedule/list",
        method: 'get',
        params: params,
    })
}

// 更新国家/地区
export const updateSchedule = (params) => {
    return http.request({
        url: "/v1/olympics/schedule/update",
        method: 'post',
        data: params,
    })
}


// 搜索
export const searchSchedule = (params) => {
    return http.request({
        url: "/v1/olympics/schedule/search",
        method: 'post',
        data: params,
    })
}

// 获取阵容
export const getScheduleLineupList = (params) => {
    return http.request({
        url: "/v1/olympics/schedule/lineup/list",
        method: 'get',
        params: params,
    })
}


export const updateScheduleLineup = (params) => {
    return http.request({
        url: "/v1/olympics/schedule/lineup/update",
        method: 'post',
        data: params,
    })
}

export const getScheduleResultList = (params) => {
    return http.request({
        url: "/v1/olympics/schedule/result/list",
        method: 'get',
        params: params,
    })
}

export const updateScheduleResult = (params) => {
    return http.request({
        url: "/v1/olympics/schedule/result/update",
        method: 'post',
        data: params,
    })
}

// 删除阵容
export const deleteScheduleLineup = (params) => {
    return http.request({
        url: "/v1/olympics/schedule/lineup/delete",
        method: 'post',
        data: params,
    })
}


// 搜索战队
export const searchScheduleTeam = (params) => {
    return http.request({
        url: "/v1/olympics/schedule/team/search",
        method: 'post',
        data: params,
    })
}

// 获取队伍
export const getScheduleTeamList = (params) => {
    return http.request({
        url: "/v1/olympics/schedule/team/list",
        method: 'get',
        params: params,
    })
}

// 更新队伍
export const updateScheduleTeam = (params) => {
    return http.request({
        url: "/v1/olympics/schedule/team/update",
        method: 'post',
        data: params,
    })
}

// 更新阵容
export const updateScheduleLineupSwitch = (params) => {
    return http.request({
        url: "/v1/olympics/schedule/lineup/update_switch",
        method: 'post',
        data: params,
    })
}