<template>
  <div>
    <Drawer
        v-model="drawer_edit"
        width="500"
        :mask-closable="true"
    >
        <scheduleEdit :_id="edit_id" @closePage="closeEditDrawer"></scheduleEdit>
    </Drawer> 
    <Drawer
        v-model="drawer_lineup"
        width="900"
        :mask-closable="true"
        :transfer="false"
    >
        <scheduleLineupList :schedule_id="schedule_id" :season_id="lineup_season_id" ></scheduleLineupList>
    </Drawer> 
    <Drawer
        v-model="drawer_relation"
        width="700"
        :mask-closable="true"
        :closable="false"
    >
        <scheduleRelation :_id="relation_match_id" @closePage="closeRelationDrawer"></scheduleRelation>
    </Drawer>  
    <Row>
        <Col  class-name='table-link-text' >
            <Input v-model="search_key" placeholder="搜索" search @on-search="onSearch" />
        </Col>
        <DatePicker type='date' format="yyyy-MM-dd" :value="date"  placeholder="选择日期" @on-change="date=$event; onDateChange()" ></DatePicker>
        <!-- <span class='text-main'>奥运会:</span>
        <seasonSearch v-model="season"></seasonSearch> -->
        <span class='text-main'>场馆:</span>
        <venueSearch v-model="venue" ></venueSearch>
        <span class='text-main'> 运动项:</span>
        <sportSearch v-model="sport" :season_id="season.id"></sportSearch>
        <span class='text-main'> 子运动项:</span>
        <subsportSearch v-model="subsport" :season_id="season.id" :sport_id="sport.id"></subsportSearch>
        <span class='text-main'> 类型:</span>
        <Row><Col>
            <Select v-model="type_id" @on-change="onTypeIdChange" style="min-width:120px">
                <Option value="-1" :key="-1">全部</Option>
                <Option v-for="item in $conf.olympics.schedule_types" :value="item.id" :key="item.id">{{item.name}}</Option>
            </Select>
        </Col></Row>
        <span class='text-main'> 队伍:</span>
        <scheduleTeamSearch v-model="team"></scheduleTeamSearch>
        <span class='text-main'> 状态:</span>
        <Row><Col>
            <Select v-model="status" @on-change="onStatusChange" style="min-width:120px">
                <Option value=-1 :key="-1">全部</Option>
                <Option v-for="item in $conf.olympics.match_status" :value="item.id" :key="item.id">{{item.name}}</Option>
            </Select>
        </Col></Row>
        <span class='text-main'> 纳米赛程:</span>
        <Row>
            <Checkbox v-model="nami_match_relation" @on-change="onRelationChange"></Checkbox>
            <Col v-if="nami_match_relation">
                <Select v-model="nami_sport_id" @on-change="onRelationChange" style="min-width:120px">
                    <Option value=0 :key="0">全部运动</Option>
                    <Option v-for="item in $conf.sports" :value="item.id" :key="item.id">{{item.name}}</Option>
                </Select>
            </Col>
        </Row>
        <!-- <Button type="default"  @click="jumpScheduleTeam">战队</Button> -->
        <Button type="success"  @click="add">新增</Button>
    </Row>
    <fitTable 
        :columns="columns" 
        :data="data" 
        :loading="loading" 
    >
    </fitTable>
    <Page
        :total="total_page"
        :current="current_page"
        :page-size="page_size"
        @on-change="onPageChange"
        show-total
        show-elevator
    ></Page>
  </div>
</template>
<script>

import { getScheduleList } from '@/api/olympics/schedule';
import scheduleEdit from './edit.vue';
import scheduleRelation from './relation.vue';
import scheduleLineupList from './schedule_lineup/list.vue';
import seasonSearch from '../season/search.vue';
import sportSearch from '../sport/search.vue';
import subsportSearch from '../subsport/search.vue';
import venueSearch from '../venue/search.vue';
import scheduleTeamSearch from './schedule_team/search.vue';
import moment from 'moment';
import fitTable from '../../basic/table.vue';

const editButton = (vm, h, params) => {
    let self = this;
    return h(
        'Button',
        {
            style: {
                margin: '2px 2px'
            },
            props: {
            },
            on: {
                click: () => {
                    vm.edit_id = params.row.id;
                    vm.drawer_edit = true;
                }
            }
        },
        '编辑'
    )
}

const lineupButton = (vm, h, params) => {
    let self = this;
    return h(
        'Button',
        {
            style: {
                margin: '2px 2px'
            },
            props: {
            },
            on: {
                click: () => {
                    vm.schedule_id = params.row.id;
                    vm.lineup_season_id = params.row.season.id;
                    vm.drawer_lineup = true;
                }
            }
        },
        '阵容'
    )
}

const relationButton = (vm, h, params) => {
    return h(
        'Button',
        {
            style: {
                margin: '2px 2px'
            },
            props: {
                type: 'success',
                size: 'small'
            },
            on: {
                click: () => {
                    // vm.edit_data = JSON.parse(JSON.stringify(params.row))
                    vm.relation_match_id = params.row.id;
                    vm.drawer_relation = true;
                }
            }
        },
        '关联'
    )
}

// const resultButton = (vm, h, params) => {
//     let self = this;
//     return h(
//         'Button',
//         {
//             style: {
//                 margin: '2px 2px'
//             },
//             props: {
//             },
//             on: {
//                 click: () => {
//                     vm.schedule_id = params.row.id;
//                     vm.result_season_id = params.row.season.id;
//                     vm.drawer_result = true;
//                 }
//             }
//         },
//         '赛果'
//     )
// }

export default {
    name: "schedule-list",
    props: {
    },
    components: {
        scheduleEdit,
        seasonSearch,
        sportSearch,
        subsportSearch,
        venueSearch,
        scheduleLineupList,
        scheduleRelation,
        scheduleTeamSearch,
        fitTable,
    },
    data () {
        let self = (window.x = this)
        return {
            season: {id:0},
            venue: {id:0},
            sport: {id:0},
            subsport: {id:0},
            team: {id:0},
            date: '',
            type_id: -1,
            status: -1,
            nami_match_relation: false,
            nami_sport_id: 0,

            drawer_edit: false,
            edit_id : 0,
            schedule_id: 0,
            drawer_lineup: false,
            lineup_season_id: 0,
            relation_match_id: 0,
            drawer_relation: false,
            // drawer_result: false,
            // result_season_id: 0,

            search_key: '',
            loading: true,
            total_page: 0,
            page_size: 0,
            current_page: 1,
            columns: [
                {
                    title: 'id',
                    align: 'center',
                    minWidth: 80,
                    key: 'id',
                },
                // {
                //     title: '奥运会',
                //     align: 'center',
                //     key: 'season',
                //     render: function (h, params) {
                //         let currentRow = params.row;
                //         var season = currentRow.season;
                //         return h('span', season.name );
                //     }
                // },
                // {
                //     title: '父赛程',
                //     align: 'center',
                //     key: 'parent',
                //     render: function (h, params) {
                //         let currentRow = params.row;
                //         var parent = currentRow.parent;
                //         if (parent.id > 0){
                //             return h('span', parent.id + '-' + parent.name );
                //         }else{
                //             return h('span', '' );
                //         }
                //     }
                // },

                {
                    title: '大运动项',
                    align: 'center',
                    key: 'season',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row;
                        var sport = currentRow.sport;
                        return h('span', sport.name );
                    }
                },
                {
                    title: '小运动项',
                    align: 'center',
                    key: 'season',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row;
                        var subsport = currentRow.subsport;
                        return h('span', subsport.name );
                    }
                },
                {
                    title: '场馆',
                    align: 'center',
                    key: 'venue',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row;
                        var venue = currentRow.venue;
                        return h('span', venue.name );
                    }
                },
                {
                    title: '中文描述',
                    align: 'center',
                    key: 'event',
                    minWidth: 100,
                },
                {
                    title: '英文描述',
                    align: 'center',
                    key: 'event_en',
                    minWidth: 100,
                },
                {
                    title: '比赛日期',
                    align: 'center',
                    key: 'match_time',
                    width: 100,
                    render: function (h, params) {
                        let currentRow = params.row;
                        var match_time = currentRow.match_time;
                        return h('span', match_time>0? moment.unix(match_time).format('YYYY-MM-DD HH:mm'): '' );
                    }
                },
                {
                    title: '类型',
                    align: 'center',
                    key: 'type',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row;
                        // 0普通比赛 1金牌赛 2开幕式 3闭幕式 4颁奖仪式 5'铜牌赛' 6'金银铜赛'
                        let _type = self.$conf.olympics.schedule_types[ currentRow.type ] || {};
                        return h('span', _type.name || '未知' );
                    }
                },
                
                {
                    title: '主代表团',
                    align: 'center',
                    key: 'season',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row;
                        var delegate = currentRow.home_delegate;
                        return h('span', delegate.name );
                    }
                },
                {
                    title: '客代表团',
                    align: 'center',
                    key: 'season',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row;
                        var delegate = currentRow.away_delegate;
                        return h('span', delegate.name );
                    }
                },
                {
                    title: '主队',
                    align: 'center',
                    key: 'home_team',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row;
                        let home_team = currentRow.home_team || {};
                        return h('span', home_team.name || '' );
                    }
                },
                {
                    title: '客队',
                    align: 'center',
                    key: 'away_team',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row;
                        let away_team = currentRow.away_team || {};
                        return h('span', away_team.name || '' );
                    }
                },
                {
                    title: '主队比分',
                    align: 'center',
                    key: 'home_score',
                    minWidth: 50,
                },
                {
                    title: '客队比分',
                    align: 'center',
                    key: 'away_score',
                    minWidth: 50,
                },
                {
                    title: '状态',
                    align: 'center',
                    key: 'status',
                    minWidth: 100,
                    render: function (h, params) {
                        let currentRow = params.row;
                        let status = self.$conf.olympics.match_status[currentRow.status] || {};
                        // return h('span', status.name || "未知状态: " + currentRow.status );
                        const status_desc = status ? status.name: "未知状态: "+ currentRow.status_id;
                        const status_type = status ? status.type : 0;
                        const status_status = status_type == 1 ? 'success': (
                            status_type == 2 ? 'processing': (
                                status_type == 3 ? 'default': 'error'
                            )
                        )
                        return [h( 'Badge', {attrs: {status: status_status,  }} ), h('span', status_desc)]
                    }
                },
                // {
                //     title: '关联',
                //     align: 'center',
                //     key: 'nami_match',
                //     minWidth: 80,
                //     render: function (h, params) {
                //         let currentRow = params.row;
                //         let nami_match = currentRow.nami_match ;
                //         // console.log(nami_match)
                //         if (nami_match.sport_id>0 && nami_match.match_id>0){
                //             let sport_id = nami_match.sport_id;
                //             let nami_match_id = nami_match.match_id;
                //             // 获取sport_name
                //             let sport = self.$conf.ballsports.sports[sport_id];
                //             return [
                //                 h('Row', sport.name_zh || ''),
                //                 h('Row', nami_match_id),
                //             ]
                //         }
                //         return h('span', '' );
                //     }
                // },
                {
                    title: '阵容状态',
                    align: 'center',
                    key: 'lineup_model',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row;
                        // 0自动 1手动
                        let lineup_model = currentRow.lineup_model;
                        let data_list = [
                            h('Row', lineup_model===0? '自动': '手动' ),
                        ];
                        let lineup_status = currentRow.lineup_status;
                        if (lineup_status['0'] || lineup_status['1']){
                            // data_list.push(h('Row', '有阵容' ),)
                            if (lineup_status['1']){
                                data_list.push(h('Row', {attrs: {class: 'text-assist'  }}, '已发布' ),)
                            }else{
                                data_list.push(h('Row', '有阵容' ),)
                                data_list.push(h('Row', {attrs: {class: 'text-link'  }}, '未发布' ),)
                            }
                        }else{
                            data_list.push(h('Row', '无阵容' ),)
                        }
                        return data_list
                    }
                },
                {
                    title: '操作',
                    key: 'handlers',
                    align: 'center',
                    width: 90,
                    render (h, params) {
                        return [ editButton( self, h, params), lineupButton( self, h, params), relationButton(self, h, params) ]
                    }
                }
            ],
            data: [],
        }
    },
    methods: {
        onRelationChange(){
            this.current_page = 1;
            this.getData();
        },
        onStatusChange(){
            this.current_page = 1;
            this.getData();
        },
        onTypeIdChange(){
            this.current_page = 1;
            this.getData();
        },
        onDateChange(){
            this.current_page = 1;
            this.getData();
        },
        onPageChange (page) {
            this.current_page = page
            this.getData()
        },
        // end
        onSearch (search_key) {
            this.current_page = 1;
            this.search_key = search_key;
            this.getData();
        },
        // end
        closeEditDrawer(){
            let self = this;
            self.drawer_edit = false;
            self.edit_id = 0;
            self.getData();
        },
        // end
        getData () {
            this.loading = true;
            if(this.date){
                // console.log(this.date);
                var date = moment( this.date).unix();
            }else{
                var date = 0;
            }
            // console.log(date)
            var params = {
                search_key: this.search_key,
                page: this.current_page,
                season_id: this.season.id,
                venue_id : this.venue.id,
                sport_id : this.sport.id,
                subsport_id: this.subsport.id,
                team_id: this.team.id,
                date: date,
                type: this.type_id,
                status: this.status,
                nami_match_relation: this.nami_match_relation?1:0,
                nami_sport_id: this.nami_sport_id,
            }
            getScheduleList(params).then(response => {
                if (response.data.code == 0) {
                    this.data = response.data.data.list;
                    this.total_page = response.data.data.total_page;
                    this.page_size = response.data.data.page_size;
                    this.current_page = response.data.data.current_page;
                    this.loading = false;
                } else {
                    this.$Message.error(response.data.msg);
                }
            })
        },
        // end 
        add () {
            this.edit_id = 0;
            this.drawer_edit = true;
        },
        // end 
        closeRelationDrawer(){
            let self = this;
        },
        // end
        jumpScheduleTeam(){
            let self = this;

        },
        // end
    },
    mounted () {
        // console.log("schedule mount");
        this.getData();
    },
    watch: {
        "season.id":{
            handler(value){
                // console.log( "season.id", value );
                let self = this;
                self.current_page = 1;
                self.getData();
            },
	        immediate: false,  // 深度触发
        },
        "venue.id":{
            handler(value){
                // console.log( "venue.id" , value );
                let self = this;
                self.current_page = 1;
                self.getData();
            },
	        immediate: false,  // 深度触发
        },
        "sport.id":{
            handler(value){
                // console.log( "sport.id", value );
                let self = this;
                self.current_page = 1;
                self.getData();
            },
	        immediate: false,  // 深度触发
        },
        "subsport.id":{
            handler(value){
                // console.log( "subsport.id", value );
                let self = this;
                self.current_page = 1;
                self.getData();
            },
	        immediate: false,  // 深度触发
        },
        "team.id":{
            handler(value){
                // console.log("team.id", value );
                let self = this;
                self.current_page = 1;
                self.getData();
            },
	        immediate: false,  // 深度触发
        },
    },
    computed: {}
}
</script>
