<template>
  <div>
    <Drawer
        v-model="drawer_add_lineup"
        width="500"
        :mask-closable="true"
        :transfer="true"
    >
        <scheduleLineupEdit ref="Lineup" :season_id="season_id" :schedule_id="schedule_id" :_id="lineup_id" @closePage='closeEditDrawer'> </scheduleLineupEdit>
    </Drawer> 

    <Drawer
        v-model="drawer_edit_result"
        width="500"
        :mask-closable="true"
        :transfer="true"
    >   
        <scheduleResultEdit :_id="edit_result_id" :season_id="season_id" 
            :schedule_id="schedule_id" :lineup_id="lineup_id" @closePage='closeResultDrawer'> </scheduleResultEdit>
    </Drawer> 
    
    <Row style="margin-bottom: 10px;" :gutter="10" align="middle">
        <Col >
            <Button type="success"  @click="add">新增阵容</Button>
        </Col>
        <Col >
            <Poptip
                confirm
                title="确认发布?"
                @on-ok="updateSwitch"
            >
                <Button type="primary" >发布线上</Button>
            </Poptip>
        </Col>
        <Col >
            <Button  shape="circle" icon="ios-refresh" @click="refresh"></Button>
        </Col>
    </Row>
    <Table 
        :row-class-name="rowClassName"
        :columns="columns" 
        :data="data" 
        :loading="loading" 
    >
    </Table>
  </div>
</template>
<script>

import { getScheduleLineupList, deleteScheduleLineup, updateScheduleLineupSwitch } from '@/api/olympics/schedule';
import playerSearch from '../../player/search.vue';
import areaSearch from '../../area/search.vue';
import delegateSearch from '../../delegation/search.vue';
import scheduleLineupEdit from './edit.vue';
import scheduleResultEdit from '../schedule_result/edit.vue';

// 编辑
const editButton = (vm, h, params) => {
    let self = this;
    return h(
        'Button',
        {
            style: {
                margin: '2px 2px'
            },
            props: {
            },
            on: {
                click: () => {
                    vm.drawer_add_lineup = true;
                    vm.lineup_id = params.row.id;
                }
            }
        },
        '编辑'
    )
}

const delButton = (vm, h, params) => {
    return h(
        'Poptip',
        {
        props: {
            confirm: true,
            title: '您确定要删除这条数据吗?',
            transfer: true
        },
        on: {
            'on-ok': () => {
                // vm.delete(params.row.id)
                // 删除本行
                var data = {
                    "id": params.row.id,
                }
                deleteScheduleLineup(data).then(response => {
                    if (response.data.code == 0) {
                        vm.data.splice(params.index, 1)
                    } else {
                        this.$Message.error(response.data.msg);
                    }
                })
            }
        }
        },
        [
            h(
                'Button',
                {
                    style: {
                        margin: '2px 2px'
                    },
                    props: {
                        type: 'warning',
                        size: 'small'
                    }
                },
                '删除'
            )
        ]
    )
}

const resultButton = (vm, h, params) => {
    let self = this;
    return h(
        'Button',
        {
            style: {
                margin: '2px 2px'
            },
            props: {
                disabled: params.row.switch===1 ? false: true,
            },
            on: {
                click: () => {
                    vm.edit_result_id = params.row.result.id ;
                    vm.drawer_edit_result = true;
                    vm.lineup_id = params.row.id;
                }
            }
        },
        '赛果'
    )
}

export default {
    name: "schedule-lineup-list",
    props: {
        schedule_id: Number,
        season_id  : Number,
    },
    components: {
        playerSearch,
        areaSearch,
        delegateSearch,
        scheduleLineupEdit,
        scheduleResultEdit,
    },
    data () {
        let self = (window.x = this)
        return {
            date: '',
            drawer_add_lineup: false,
            drawer_edit_result: false,
            edit_result_id: 0,
            lineup_id: 0,
            drawer_edit: false,
            edit_id : 0,
            search_key: '',
            loading: true,
            total_page: 0,
            page_size: 0,
            current_page: 1,
            data: [],
            columns: [
                {
                    title: 'id',
                    align: 'center',
                    width: 80,
                    key: 'id',
                },
                // {
                //     title: '奥运会',
                //     align: 'center',
                //     key: 'season',
                //     render: function (h, params) {
                //         let currentRow = params.row;
                //         var season = currentRow.season;
                //         return h('span', season.name );
                //     }
                // },
                {
                    title: '国家/地区',
                    align: 'center',
                    key: 'area',
                    width: 80,
                    render: function (h, params) {
                        let currentRow = params.row;
                        var area = currentRow.area;
                        return h('span', area.name );
                    }
                },
                {
                    title: '代表团',
                    align: 'center',
                    key: 'delegate',
                    width: 120,
                    render: function (h, params) {
                        let currentRow = params.row;
                        var delegate = currentRow.delegate;
                        return h('span', delegate.name );
                    }
                },
                {
                    title: '选手',
                    align: 'left',
                    key: 'players',
                    // width: 250,
                    render: function (h, params) {
                        let currentRow = params.row;
                        let data_list = [];
                        currentRow.players.forEach(element => {
                            var color = 'default';
                            if (element.type === 1){
                                color = 'blue';
                            }
                            data_list.push( 
                                h('Tag', { attrs:{type:"border", color} }, element.name),
                            )
                        });
                        if (data_list.length > 0){
                            return data_list
                        }else{
                            return h('span', ' ')
                        }
                    }
                },
                
                {
                    title: '赛果',
                    align: 'center',
                    key: 'result',
                    width: 150,
                    render: function (h, params) {
                        let currentRow = params.row;
                        if (currentRow.result){
                            let data_list = [];
                            data_list.push(
                                h('Row', "赛果: " + currentRow.result.result || '无')
                            )
                            data_list.push(
                                h('Row', "名次: " + currentRow.result.ranking || '无')
                            )
                            let remark = self.$conf.olympics.remarks[ currentRow.result.remark ]
                            if (remark){
                                data_list.push(
                                    h('Row', "备注: " + remark.desc || '无')
                                )
                            }
                            // 单位
                            let unit = self.$conf.olympics.result_units[currentRow.result.unit] || {};
                            if (unit){
                                data_list.push(
                                    h('Row', "单位: " + unit.name )
                                )
                            }
                            return data_list
                        }
                        return h('span', '空')
                    }
                },

                {
                    title: '操作',
                    key: 'handlers',
                    align: 'center',
                    width: 100,
                    render (h, params) {
                        return [ editButton( self, h, params), delButton( self, h, params), resultButton( self, h, params) ]
                    }
                }
            ],
        }
    },
    methods: {
        getData () {
            if (this.schedule_id && this.schedule_id > 0){
                this.loading = true;
                var params = {
                    schedule_id: this.schedule_id,
                }
                getScheduleLineupList(params).then(response => {
                    if (response.data.code == 0) {
                        this.data = response.data.data.list;
                        this.loading = false;
                    } else {
                        this.$Message.error(response.data.msg);
                    }
                })
            }else{
                this.data = [];
            }
        },
        // end
        updateSwitch(){
            let self = this;
            var lineup_ids = [];
            self.data.forEach(element => {
                if (element.switch === 0 && lineup_ids.indexOf(element.id) === -1){
                    lineup_ids.push(element.id)
                }
            })
            if (lineup_ids.length > 0){
                let params = {
                    lineup_ids,
                }
                updateScheduleLineupSwitch(params).then(response => {
                    if (response.data.code == 0) {
                        self.$Message.success(response.data.msg);
                        self.getData();
                    } else {
                        self.$Message.error(response.data.msg);
                    }
                })
            }
        } ,
        // end  
        add () {
            let self = this;
            self.drawer_add_lineup = true;
            self.lineup_id = 0;
            self.$refs.Lineup.clearData();
        },
        refresh(){
            let self = this;
            self.getData();
        },
        // end
        closeEditDrawer(){
            let self = this;
            self.drawer_add_lineup = false;
            self.getData();
        },
        // end 
        closeResultDrawer(){
            let self = this;
            self.edit_result_id = null;
            self.drawer_edit_result = false;
            self.getData();
        },
        // end 
        rowClassName (row, index) {
            // console.log(row);
            if (row.switch === 1) {
                return ''  //'demo-table-info-row';
            } else  {
                return 'demo-table-disable-row';
            }
        },
        // end
        
    },
    mounted () {
        this.getData();
    },
    watch: {
        "schedule_id":{
            handler(value){
                // console.log( 'in list:', this.schedule_id, this.season_id );
                let self = this;
                self.getData();
            },
	        immediate: true,  // 深度触发
        },
    },
    computed: {}
}
</script>
