<template>
    <div>
        <Spin size="large" fix v-if="spinShow"></Spin>
        <Tabs value="1">
            <TabPane label="赛果信息" name="1">
                <Form label-position="left" :label-width="80" >
                    <FormItem label="id: " v-if="data.id">
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.id" disabled></Input>
                            </Col>
                        </Row>
                    </FormItem>

                    <FormItem label="比赛结果: ">
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.result"></Input>
                            </Col>
                        </Row>
                    </FormItem>

                    <FormItem label="单位: ">
                        <Row>
                            <Col span="20" >
                                <Select v-model="data.unit" >
                                    <Option v-for="item in $conf.olympics.result_units" :key="item.id" :value="item.id" >{{item.name}}</Option>
                                </Select>
                            </Col>
                        </Row>
                    </FormItem>

                    <FormItem label="名次(数字): ">
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.ranking"></Input>
                            </Col>
                        </Row>
                    </FormItem>

                    <FormItem label="奖牌id: ">
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.medal_id"></Input>
                            </Col>
                        </Row>
                    </FormItem>

                    <FormItem label="备注信息: ">
                        <Row>
                            <Col span="20" >
                                <Select v-model="data.remark" >
                                    <Option v-for="item in $conf.olympics.remarks" :value="item.remark" :key="item.remark">{{item.desc}}</Option>
                                </Select>
                            </Col>
                        </Row>
                    </FormItem>

                    <FormItem label="是否删除: ">
                        <Row>
                            <Col span="20" >
                                <Select v-model="data.deleted" >
                                    <Option :value="1" key="1">是</Option>
                                    <Option :value="0" key="0">否</Option>
                                </Select>
                            </Col>
                        </Row>
                    </FormItem>
                </Form>
            </TabPane>
        </Tabs>
        <Row align="middle">
            <Button type="primary"  @click="save" long size="default">保存</Button>
        </Row>
    </div>
</template>
<script>

import { getScheduleResultList, updateScheduleResult } from '@/api/olympics/schedule';

export default {
    name: "schedule-result-edit",
    props: {
        _id: Number,   // id
        season_id  : Number,
        schedule_id: Number,
        lineup_id  : Number,
    },
    components: {
    },
    data () {
        let self = (window.x = this)
        return {
            spinShow: false,
            modal_add_player: false,
            new_player: {
                id: 0,
                name: '',
            },
            data: {
                id: 0,
                deleted: 0,
            },
        }
    },
    methods: {
        // 获取联赛分类
        getData () {
            let self = this;
            if (self._id && self._id > 0){
                this.spinShow = true;
                // 搜索日期
                var params = {
                    "id" : self._id,
                }
                getScheduleResultList( params ).then(response => {
                    if (response.data.code == 0) {
                        if (response.data.data.list && response.data.data.list.length > 0){
                            this.data = response.data.data.list[0];
                        }else{
                            this.data = {};
                        }
                        self.spinShow = false;
                    } else {
                        self.$Message.error(response.data.msg);
                    }
                })
            }else{
                self.data = {
                    id: 0,
                    deleted: 0,
                }
            }
        },
        // end
        emit(func_name){
            this.$emit('closePage', func_name);
        },

        save() {
            let self = this;
            let params = {
                id: self.data.id,
                season_id: self.season_id,
                schedule_id: self.schedule_id,
                lineup_id: self.lineup_id,
                result: self.data.result,
                ranking: self.data.ranking,
                remark: self.data.remark,
                medal_id: self.data.medal_id,
                unit: self.data.unit,
                deleted: self.data.deleted,
            }
            // console.log(params);
            updateScheduleResult(params).then(response => {
                if (response.data.code == 0) {
                    this.$Message.success("保存成功");
                    self.emit("save");
                } else {
                    this.$Message.error(response.data.msg)
                }
            })
        },
        // end 
    },
    mounted () {
        // let self = this;
    },
    watch: {
        "_id":{
            handler(value){
                let self = this;
                self.getData();
            },
	        immediate: true,  // 深度触发
        },
        "season_id": {
            handler(value){
                let self = this;
                self.getData();
            },
	        immediate: true,  // 深度触发
        },
        'schedule_id': {
            handler(value){
                let self = this;
                self.getData();
            },
	        immediate: true,  // 深度触发
        },
    },
    computed: {
    }
}
</script>
