<template>
    <div>
        <Spin size="large" fix v-if="spinShow"></Spin>
        <Tabs value="1">
            <TabPane label="基础信息" name="1">
            <Form label-position="left" :label-width="80" >
                <FormItem label="id: " v-if="data.id">
                    <Row>
                        <Col span="20" >
                            <Input v-model="data.id" disabled></Input>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="奥运会: ">
                    <Row>
                        <Col span="20" >
                            <seasonSearch v-model="data.season" ></seasonSearch>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="父赛程: ">
                    <Row>
                        <Col span="20" >
                            <scheduleSearch v-model="data.parent" ></scheduleSearch>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="主代表团: ">
                    <Row>
                        <Col span="20" >
                            <delegateSearch v-model="data.home_delegate" :season_id="data.season.id"></delegateSearch>
                        </Col>
                    </Row>
                </FormItem>
                <FormItem label="客代表团: ">
                    <Row>
                        <Col span="20" >
                            <delegateSearch v-model="data.away_delegate" :season_id="data.season.id"></delegateSearch>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="主队: " >
                    <Row>
                        <Col span="20" >
                            <scheduleTeamSearch v-model="data.home_team"></scheduleTeamSearch>
                        </Col>
                    </Row>
                </FormItem>
                <FormItem label="客队: " >
                    <Row>
                        <Col span="20" >
                            <scheduleTeamSearch v-model="data.away_team"></scheduleTeamSearch>
                        </Col>
                    </Row>
                </FormItem>
                <FormItem label="主队比分: " >
                    <Row>
                        <Col span="20" >
                            <Input v-model="data.home_score"></Input>
                        </Col>
                    </Row>
                </FormItem>
                <FormItem label="客队比分: " >
                    <Row>
                        <Col span="20" >
                            <Input v-model="data.away_score"></Input>
                        </Col>
                    </Row>
                </FormItem>
                <FormItem label="状态: " >
                    <Row>
                        <Col span="20" >
                            <Select v-model="data.status" >
                                <Option v-for="item in $conf.olympics.match_status" :key="item.id" :value="item.id" >{{item.name}}</Option>
                            </Select>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="大运动项: ">
                    <Row>
                        <Col span="20" >
                            <sportSearch v-model="data.sport" :season_id="data.season.id"></sportSearch>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="小运动项: ">
                    <Row>
                        <Col span="20" >
                            <subsportSearch v-model="data.subsport" :season_id="data.season.id" :sport_id="data.sport.id"></subsportSearch>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="场馆: ">
                    <Row>
                        <Col span="20" >
                            <venueSearch v-model="data.venue" :season_id="data.season.id"></venueSearch>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="中文描述: ">
                    <Row>
                        <Col span="20" >
                            <Input v-model="data.event"></Input>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="英文描述: ">
                    <Row>
                        <Col span="20" >
                            <Input v-model="data.event_en"></Input>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="比赛时间: " >
                    <Row>
                        <Col span="20" >
                            <DatePicker 
                                type="datetime" 
                                format="yyyy-MM-dd HH:mm" 
                                style="width: 100%"
                                v-model="match_time"
                            >
                            </DatePicker>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="类型: ">
                    <Row>
                        <Col span="20" >
                            <Select v-model="data.type" >
                                <Option v-for="_type in $conf.olympics.schedule_types" :value="_type.id" :key="_type.id" :index="_type.id">{{_type.name}}</Option>
                            </Select>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="数据更新: ">
                    <Row>
                        <Col span="20" >
                            <Select v-model="data.model" >
                                <Option :value="0" key="0">自动</Option>
                                <Option :value="1" key="1">手动</Option>
                                <Option :value="2" key="2">半自动</Option>
                            </Select>
                        </Col>
                    </Row>
                </FormItem>
                <FormItem label="阵容更新: ">
                    <Row>
                        <Col span="20" >
                            <Select v-model="data.lineup_model" >
                                <Option :value="0" key="0">自动</Option>
                                <Option :value="1" key="1">手动</Option>
                            </Select>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="是否删除: ">
                    <Row>
                        <Col span="20" >
                            <Select v-model="data.deleted" >
                                <Option :value="1" key="1">是</Option>
                                <Option :value="0" key="0">否</Option>
                            </Select>
                        </Col>
                    </Row>
                </FormItem>
            </Form>
            </TabPane>
        </Tabs>
        <Row align="middle">
            <Button type="primary"  @click="save" long size="default">保存</Button>
        </Row>
    </div>
</template>
<script>

import { getScheduleList, updateSchedule } from '@/api/olympics/schedule';
import scheduleSearch from '../schedule/search.vue';
import seasonSearch from '../season/search.vue';
import sportSearch from '../sport/search.vue';
import subsportSearch from '../subsport/search.vue';
import venueSearch from '../venue/search.vue';
import delegateSearch from '../delegation/search.vue';
import scheduleTeamSearch from './schedule_team/search.vue';
import moment from 'moment';

export default {
    name: "schedule-edit",
    props: {
        _id: Number,   // id
    },
    components: {
        scheduleSearch,
        seasonSearch,
        sportSearch,
        subsportSearch,
        venueSearch,
        delegateSearch,
        scheduleTeamSearch,
    },
    data () {
        let self = (window.x = this)
        return {
            type: 1, 
            spinShow: false,
            data: {
                id: 0,
                parent: {},
                season: {id:1, name:'2020夏季奥林匹克运动会'},
                sport: {},
                subsport: {},
                venue: {},
                home_delegate: {},
                away_delegate: {},
                home_team: {},
                away_team: {},
            },
        }
    },
    methods: {
        // 获取联赛分类
        getData () {
            let self = this;
            if (self._id && self._id > 0){
                this.spinShow = true;
                // 搜索日期
                var params = {
                    "id" : self._id,
                }
                getScheduleList( params ).then(response => {
                    if (response.data.code == 0) {
                        if (response.data.data.list && response.data.data.list.length > 0){
                            this.data = response.data.data.list[0];
                        }else{
                            this.data = {};
                        }
                        self.spinShow = false;
                    } else {
                        self.$Message.error(response.data.msg);
                    }
                })
            }else{
                self.data = {
                    id: 0,
                    parent: {},
                    season: {id:1, name:'2020夏季奥林匹克运动会'},
                    sport: {},
                    subsport: {},
                    venue: {},
                    home_delegate: {},
                    away_delegate: {},
                    home_team: {},
                    away_team: {},
                }
            }
        },
        // end
        emit(func_name){
            this.$emit('closePage', func_name);
        },

        save() {
            let self = this;
            let params = {
                id: self.data.id,
                sport_id: self.data.sport.id,
                sup_sport_id: self.data.subsport.id,
                parent_id: self.data.parent.id,
                season_id: self.data.season.id,
                event: self.data.event,
                event_en: self.data.event_en,
                venue_id: self.data.venue.id,
                match_time: self.data.match_time,
                type : self.data.type ,
                deleted: self.data.deleted,
                home_delegate_id: self.data.home_delegate.id,
                away_delegate_id: self.data.away_delegate.id,
                home_team_id: self.data.home_team.id,
                away_team_id: self.data.away_team.id,
                home_score: self.data.home_score,
                away_score: self.data.away_score,
                status: self.data.status,
                model : self.data.model,
                lineup_model: self.data.lineup_model,
            }
            // console.log(params);
            updateSchedule(params).then(response => {
                if (response.data.code == 0) {
                    this.$Message.success("保存成功");
                    self.emit("save");
                } else {
                    this.$Message.error(response.data.msg)
                }
            })
        },
        // end 
    },
    mounted () {
        // let self = this;
    },
    watch: {
        "_id":{
            handler(value){
                // console.log( value );
                let self = this;
                self.getData();
            },
	        immediate: true,  // 深度触发
        }
    },
    computed: {
        match_time: {
            get: function () {
                if (this.data.match_time){
                    return moment.unix(this.data.match_time).toDate();
                }else{
                    return ''; // new Date()  ;
                }
            },
            set: function (newValue) {
                this.data.match_time = moment(newValue).unix();
            }
        },
    }
}
</script>
